//Template1  = Video Happy Doctor Day

import React, { useRef, useState, useEffect } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import { useParams, NavLink, useNavigate } from "react-router-dom";

import Sidebar from "./Sidebar/Sidebar";
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Template1.css";
// import Image1 from "../components/assets/doctorimg.jpg";
import Image3 from "../components/assets/thumb.png";
import { Oval } from "react-loader-spinner";

const Template2 = () => {
  const videoRef = useRef(null);
  const { videoname, MRID, name } = useParams();
  const EmailId = localStorage.getItem("EmailId");
  const ObjID = localStorage.getItem("mrID");
  // console.log(name);
  // console.log(videoname);
  const [showWatermark, setShowWatermark] = useState(false);
  const [binaryVideoData, setBinaryVideoData] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processTime, setProcessTime] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [videoFile, setVideoFile] = useState("");
  const [textName, setTextName] = useState("");
  const [doctorName, setDoctorName] = useState("");

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 640);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const notify = () =>
    toast.success("Doctor Image Added!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const styles = {
    wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "60vh",
    },
    loaderContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "80px", // Match the Oval loader's size
      height: "80px", // Match the Oval loader's size
    },
    text: {
      position: "absolute",
      margin: 0,
      fontSize: "1em", // Adjust font size as needed
      color: "white", // Change text color if needed
    },
  };

  const navigate = useNavigate();

  const failed = () => {
    toast.error("Please Check the Fields or Add Video!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const charLimit = 19;

  const handleInputTextChange1 = (e) => {
    const value = e.target.value;

    if (value.length <= charLimit) {
      setDoctorName(e.target.value);
    }
  };

  const remainingCharacters1 = Math.max(0, charLimit - doctorName.length);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const incrementProgress = (setter, start, end, interval) => {
    let progress = start;
    const step = 5; // Increment by 5%
    const increment = () => {
      progress += step;
      if (progress >= end) {
        progress = end;
      }
      setter(progress);
      if (progress < end) {
        setTimeout(increment, interval);
      }
    };
    increment();
  };

  // Modify your onClick handler for Download Video button to use processSelectedImages
  // const handleDownloadClick = async () => {
  //   if (!videoFile) {
  //     alert('Please select a video file.');
  //     return;
  //   }

  //   if (!textName) {
  //     alert('Please enter a text name.');
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append('textName', textName);
  //   formData.append('videoFile', videoFile);

  //   try {
  //     // Reset progress indicators
  //     setUploadProgress(0);
  //     setDownloadProgress(0);
  //     setIsDownloading(true);

  //     // Track upload progress
  //     await axios.post('https://dummy.digilateral.com/api/auth/auth/process-video', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data'
  //       },
  //       onUploadProgress: (progressEvent) => {
  //         if (progressEvent.event.lengthComputable) {
  //           setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
  //         }
  //       }
  //     });

  //     if (response.status !== 200) {
  //       throw new Error(`Server responded with status: ${response.status}`);
  //     }

  //     // Create a URL for the response Blob and download the file
  //     const blob = new Blob([response.data], { type: response.data.type });
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = `Dr. ${textName}.mp4`;
  //     a.click();
  //     URL.revokeObjectURL(url);

  //     alert('Processing complete. Your video is downloading.');
  //   } catch (error) {
  //     alert(`Error: ${error.message}`);
  //   } finally {
  //     // Reset progress indicators
  //     setUploadProgress(0);
  //     setDownloadProgress(0);
  //     setIsDownloading(false);
  //   }
  // };

  // const handleDownloadClick = async () => {
  //   if (!videoFile) {
  //     alert('Please select a video file.');
  //     return;
  //   }

  //   if (!textName) {
  //     alert('Please enter a text name.');
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append('textName', textName);
  //   formData.append('videoFile', videoFile);

  //   try {
  //     setDownloadProgress(0);
  //     setIsProcessing(true);

  //     // Simulate upload progress incrementally
  //     incrementProgress(setDownloadProgress, 0, 70, 7000); // 5% increments every 5 seconds up to 50%

  //     const response = await axios.post('https://dummy.digilateral.com/api/auth/auth/process-video', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //       responseType: 'blob',
  //     });

  //     const userData = await axios.post(`https://dummy.digilateral.com/api/auth/auth/submitUsage/${MRID}`,{
  //       type: "videocard",
  //       doctorName: textName,
  //       videoname: videoFile.name,
  //       fileName: `Dr. ${textName}.mp4`,
  //       processTime: '29s',
  //       MRID: MRID,
  //       MBSIZE: fileSizeMB,
  //       status: "success"
  //     })

  //     console.log(userData)

  //     if (response.status !== 200) {
  //       throw new Error(`Server responded with status: ${response.status}`);
  //     }

  //     // Immediately set to 100% after successful API call
  //     setDownloadProgress(100);

  //     setTimeout(()=>{
  //       navigate(`/home/${MRID}`)
  //     },1400)

  //     const blob = new Blob([response.data], { type: response.data.type });
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = `Dr. ${textName}.mp4`;
  //     a.click();
  //     URL.revokeObjectURL(url);

  //     // alert('Processing complete. Your video is downloading.');
  //   } catch (error) {
  //     alert(`Error: ${error.message}`);
  //   } finally {
  //     setIsProcessing(false);
  //   }
  // };

  // const handleVideoChange = async (e) => {
  //   const selectedFiles = e.target.files;
  //   if (selectedFiles.length > 0) {
  //     setVideoFile(selectedFiles[0]);
  //   }
  // };

  // const handleDownloadClick = async () => {
  //   if (!videoFile) {
  //     // alert("Please select a video file.");
  //     failed("Please select a video file.");
  //     return;
  //   }

  //   if (!textName) {
  //     failed("Please enter a text name.");
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append("textName", textName);
  //   formData.append("videoFile", videoFile);

  //   try {
  //     setDownloadProgress(0);
  //     setIsProcessing(true);
  //     setLoading(true);
  //     setModalIsOpen(true);

  //     // Record start time
  //     const startTime = new Date().getTime();

  //     // Simulate upload progress incrementally
  //     incrementProgress(setDownloadProgress, 0, 70, 7000); // Simulate progress

  //     const response = await axios.post(
  //       "https://dummy.digilateral.com/api/auth/auth/process-video",
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //         responseType: "blob",
  //       }
  //     );

  //     if (response.status !== 200) {
  //       setLoading(false);
  //       navigate(`/home/${MRID}`);
  //       throw new Error(`Server responded with status: ${response.status}`);
  //     }

  //     // Record end time and calculate process time
  //     const endTime = new Date().getTime();
  //     const processingTime = ((endTime - startTime) / 1000).toFixed(2); // Convert to seconds and round
  //     console.log(processingTime);
  //     setProcessTime(processingTime);

  //     // Immediately set to 100% after successful API call
  //     setDownloadProgress(100);

  //     setTimeout(() => {
  //       navigate(`/home/${MRID}`);
  //       setLoading(false);
  //     }, 1400);

  //     const blob = new Blob([response.data], { type: response.data.type });
  //     const fileSizeMB = (blob.size / (1024 * 1024)).toFixed(2);
  //     console.log(fileSizeMB);
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = `Dr. ${textName}.mp4`;
  //     a.click();
  //     URL.revokeObjectURL(url);

  //     // Send usage data
  //     // const userData = await axios.post(
  //     //   `https://dummy.digilateral.com/api/auth/auth/submitUsage/${MRID}`,
  //     //   {
  //     //     type: "videocard",
  //     //     doctorName: textName,
  //     //     videoname: name,
  //     //     fileName: `Dr. ${textName}.mp4`,
  //     //     processTime: `${processingTime}s`,
  //     //     MRID: MRID,
  //     //     MBSIZE: fileSizeMB,
  //     //     status: response.status === 200 ? "success" : "failed",
  //     //   }
  //     // );
  //   } catch (error) {
  //     // alert(`Error: ${error.message}`);
  //     setLoading(false);
  //     setTimeout(() => {
  //       navigate(`/home/${MRID}`);
  //       setLoading(false);
  //     }, 1400);
  //     toast.error(`${error.message}`);
  //   } finally {
  //     setIsProcessing(false);
  //   }
  // };

  const handleDownloadClick = async () => {
    // Check if the required fields are provided
    if (!doctorName) {
      toast.error("Please enter a text name.");
      return;
    }
  
    if (!videoFile) {
      toast.error("Please select a video file.");
      return;
    }
  
    // Show the success toast
    toast.success("You will receive the video on your email shortly.");
  
    // Delay the navigation to ensure the toast is shown
    setTimeout(() => {
      navigate(`/home/${MRID}`);
    }, 5000); // Adjust the delay time as needed
  
    const formData = new FormData();
    formData.append("doctorName", doctorName);
    formData.append("fileName", videoFile);
    formData.append("emailId", EmailId);
  
    console.log("formdata", [...formData]);
  
    try {
      setDownloadProgress(0);
      setIsProcessing(true);
      setLoading(true);
  
      // Make the API request to process the video
      const response = await axios.post(
        `https://dummy.digilateral.com/api/auth/auth/process-video/${MRID}/${ObjID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob", // Optional if the response needs to be downloaded
        }
      );
  
      if (response.status !== 200) {
        throw new Error(`Server responded with status: ${response.status}`);
      }
  
      // Set progress to 100% on successful response
      setDownloadProgress(100);
  
      // Handle the video download (if necessary)
      // const blob = new Blob([response.data], { type: response.data.type });
      // const url = URL.createObjectURL(blob);
      // const a = document.createElement("a");
      // a.href = url;
      // a.download = `Dr. ${doctorName}.mp4`; // Example download name
      // a.click();
      // URL.revokeObjectURL(url);
  
    } catch (error) {
      // Handle errors during the request
      setLoading(false);
      setDownloadProgress(0);
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsProcessing(false);
      setLoading(false);
    }
  };
  

  // const handleDownloadClick = async () => {
  //   if (!videoFile) {
  //     toast.error("Please select a video file.");
  //     return;
  //   }

  //   if (!textName) {
  //     toast.error("Please enter a text name.");
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append("textName", textName);
  //   formData.append("videoFile", videoFile);

  //   try {
  //     setDownloadProgress(0);
  //     setIsProcessing(true);
  //     setLoading(true);
  //     setModalIsOpen(true);

  //     // const startTime = new Date().getTime();
  //     // const maxTime = 16 * 60 * 1000;
  //     // const progressUpdateInterval = 7000;

  //     // const interval = setInterval(() => {
  //     //   const elapsedTime = new Date().getTime() - startTime;
  //     //   const percentage = Math.min((elapsedTime / maxTime) * 100, 100);

  //     //   // Ensure percentage does not exceed 90%
  //     //   if (percentage > 90) {
  //     //     percentage = 90;
  //     //   }
  //     //   setDownloadProgress(Math.floor(percentage));
  //     // }, progressUpdateInterval);

  //     const startTime = new Date().getTime();
  //     const maxTime = 16 * 60 * 1000; // 16 minutes in milliseconds
  //     let currentPercentage = 0;

  //     // First interval for 5% increments up to 70%
  //     const interval1 = setInterval(() => {
  //       currentPercentage += 5;
  //       if (currentPercentage >= 70) {
  //         currentPercentage = 70;
  //         clearInterval(interval1);
  //         // Start second interval for 2% increments up to 90%
  //         const interval2 = setInterval(() => {
  //           currentPercentage += 2;
  //           if (currentPercentage >= 90) {
  //             currentPercentage = 90;
  //             clearInterval(interval2);
  //           }
  //           setDownloadProgress(currentPercentage);
  //         }, 4000); // 4 seconds
  //       }
  //       setDownloadProgress(currentPercentage);
  //     }, 15000); // 7 seconds

  //     const response = await axios.post(
  //       `https://dummy.digilateral.com/api/auth/auth/process-video/${MRID}`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //         responseType: "blob",
  //       }
  //     );

  //     if (response.status !== 200) {
  //       setLoading(false);
  //       navigate(`/home/${MRID}`);
  //       throw new Error(`Server responded with status: ${response.status}`);
  //     }

  //     const endTime = new Date().getTime();
  //     const processingTime = ((endTime - startTime) / 1000).toFixed(2); // Convert to seconds and round
  //     console.log(`Processing time: ${processingTime} seconds`);
  //     setProcessTime(processingTime);
  //     setDownloadProgress(100); // Set progress to 100%

  //     setTimeout(() => {
  //       navigate(`/home/${MRID}`);
  //       setLoading(false);
  //     }, 1400);

  //     const blob = new Blob([response.data], { type: response.data.type });
  //     const fileSizeMB = (blob.size / (1024 * 1024)).toFixed(2);
  //     console.log(`File size: ${fileSizeMB} MB`);
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = `Dr. ${textName}.mp4`;
  //     a.click();
  //     URL.revokeObjectURL(url);

  //     // Send usage data
  //     // const userData = await axios.post(
  //     //   `https://dummy.digilateral.com/api/auth/auth/submitUsage/${MRID}`,
  //     //   {
  //     //     type: "videocard",
  //     //     doctorName: textName,
  //     //     videoname: name,
  //     //     fileName: `Dr. ${textName}.mp4`,
  //     //     processTime: `${processingTime}s`,
  //     //     MRID: MRID,
  //     //     MBSIZE: fileSizeMB,
  //     //     status: response.status === 200 ? "success" : "failed",
  //     //   }
  //     // );
  //   } catch (error) {
  //     setLoading(false);
  //     setDownloadProgress(0); // Optionally reset progress to 0
  //     setTimeout(() => {
  //       navigate(`/home/${MRID}`);
  //       setLoading(false);
  //     }, 1400);
  //     toast.error(`Error: ${error.message}`);
  //   } finally {
  //     setIsProcessing(false);
  //   }
  // };

  const handleVideoChange = async (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      const videoFile = selectedFiles[0];

      const video = document.createElement("video");

      video.onloadedmetadata = () => {
        // Check if the duration is exactly 10 seconds (or within a small tolerance, if needed)
        const duration = video.duration;
        // console.log('duration',duration)

        if (Math.abs(duration - 92.2) < 0.1) {
          // A tolerance of 0.1 seconds can be used for precision issues
          setVideoFile(videoFile);
          console.log("Video selected:", videoFile);
        } else {
          alert("Please select a video that is exactly 92 seconds long.");
          // Reset the file input value to clear the selection
          e.target.value = null;
        }

        // Revoke the object URL to free up memory
        URL.revokeObjectURL(video.src);
      };

      // Handle the case where the metadata loading fails
      video.onerror = () => {
        alert("Error loading the video file. Please try a different file.");
        e.target.value = null;
      };

      // Set the src to the selected video file to trigger metadata loading
      video.src = URL.createObjectURL(videoFile);
    }
  };

  // Fetch the binary video data from the server
  // useEffect(() => {
  //   axios
  //     .get(`https://dummy.digilateral.com/${videoname}`, {
  //       responseType: "arraybuffer",
  //     })
  //     .then((res) => {
  //       // Create a Blob from the binary data
  //       const blob = new Blob([res.data], { type: "video/mp4" });
  //       setBinaryVideoData(blob);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  // Update the video source when binary data changes
  // useEffect(() => {
  //   if (binaryVideoData) {
  //     // Create a URL for the Blob
  //     const videoURL = URL.createObjectURL(binaryVideoData);

  //     // Set the video source to the created URL
  //     const videoPlayer = videoRef.current;
  //     if (videoPlayer) {
  //       videoPlayer.src = videoURL;
  //     }
  //   }
  // }, [binaryVideoData]);

  useEffect(() => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    return () => {
      document.removeEventListener("contextmenu", (event) =>
        event.preventDefault()
      );
    };
  }, []);

  return (
    <>
      <div className="flex justify-center items-center flex-col overflow-x-hidden ">
        <div className="w-[100vw]">
          <Sidebar />
        </div>

        <div className="absolute top-[34px] w-[335px]  sm:w-[650px] lg:w-[700px] xl:left-[450px] lg:left-[150px] sm:left-[55px]   ">
          <div className=" w-full">
            <div className="flex flex-row justify-between items-center relative top-[-8px] ">
              <NavLink
                className="p-5 flex relative left-[-36px] "
                to={`/home/${MRID}`}
              >
                <div
                  style={{ backgroundColor: "#F58420", color: "white" }}
                  className="p-[12px] drop-shadow-lg    h-10 w-10   rounded-full relative  top-[-4px] left-[17px] sm:left-[20px]  "
                >
                  <IoMdArrowRoundBack />
                </div>
              </NavLink>
              <div className="text-black text-[20px] font-bold relative left-[-120px] lg:left-[-320px] sm:left-[-270px] top-[-2px]  ">
                <p>Yoga Day</p>
              </div>
            </div>
            <div className="font-bold text-[11px] top-[-34px] relative left-[105px] sm:left-[251px] w-[250px]   sm:text-center   text-[rgba(158,156,158,1)]">
              <p></p>
            </div>
          </div>

          <div className="">
            <div className="w-[335px]  relative sm:w-auto top-[-30px]">
              <div>
                <div>
                  <img width="900" src={Image3} alt="Image1" />
                </div>

                <br />
                <div className="w-full relative   ">
                  <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left">
                    <label className="">Doctor Name*</label>
                  </div>

                  <input
                    type="text"
                    className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[10px] sm:left-[10px] "
                    value={doctorName}
                    onChange={handleInputTextChange1}
                    placeholder="Enter Doctor Name(without Dr. prefix)"
                  />

                  <div className="text-sm text-gray-500 flex justify-end mr-[10px]">
                    {remainingCharacters1} of {charLimit}
                  </div>

                  <div
                    className="relative left-[10px] w-full  mb-[10px]"
                    style={{ display: "inline-block", marginRight: "10px" }}
                  >
                    <input
                      type="file"
                      accept="video/mp4"
                      onChange={(e) => handleVideoChange(e)}
                    />

                    <p
                      class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                      id="file_input_help"
                    >
                      {" "}
                      MP4
                    </p>
                  </div>

                  <br />
                  <ToastContainer />
                  <button
                    onClick={handleDownloadClick}
                    disabled={isProcessing}
                    className="bg-green-500  text-white mt-[20px] rounded-full  w-full h-[30px]"
                  >
                    {isProcessing ? "Processing..." : "Download"}
                  </button>
                </div>
              </div>

              {/* <div style={styles.wrapper}>
                  <div style={styles.loaderContainer}>
                    <Oval
                      visible={true}
                      height="80"
                      width="80"
                      color="violet"
                      secondaryColor="violet"
                      ariaLabel="oval-loading"
                    />
                    <h4 style={styles.text}>{downloadProgress}%</h4>
                  </div>
                </div> */}
              {loading ? (
                <ReactModal
                  isOpen={modalIsOpen}
                  // onRequestClose={() => setModalIsOpen(false)}
                  style={{
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    },
                    content: {
                      // top: "50%",
                      // left: "53%",
                      // right: "auto",
                      // bottom: "auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // marginRight: "-4%",
                      marginRight: isSmallScreen ? "0px" : "-4%",
                      // transform: "translate(-50%, -50%)",
                      borderRadius: "10px",
                      // padding: "20px",
                      textAlign: "center",
                      backgroundColor: "transparent",
                      border: "none",
                      boxShadow: "none",
                    },
                  }}
                  contentLabel="Processing Modal"
                >
                  <div style={styles.wrapper}>
                    <div style={styles.loaderContainer}>
                      <Oval
                        visible={true}
                        height="80"
                        width="80"
                        color="orange"
                        secondaryColor="orange"
                        ariaLabel="oval-loading"
                      />
                      <h4 style={styles.text}>{downloadProgress}%</h4>
                    </div>
                  </div>
                </ReactModal>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      {/* // new Ui  */}
    </>
  );
};

export default Template2;

