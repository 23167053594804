//Template1  = Video Happy Doctor Day

import React, { useRef, useState, useEffect } from "react";
import ReactModal from "react-modal";
import axios from "axios";
import { useParams, NavLink, useNavigate } from "react-router-dom";

import Sidebar from "./Sidebar/Sidebar";
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Template1.css";
// import Image1 from "../components/assets/doctorimg.jpg";
import Image9 from "../components/assets/thumbnail_VideoCleaning.jpg";
import { Oval } from "react-loader-spinner";

const VideoCleaning = () => {
  const videoRef = useRef(null);
  const { videoname, MRID, name } = useParams();
  const EmailId = localStorage.getItem("EmailId");
  const ObjID = localStorage.getItem("mrID");
  // console.log(name);
  // console.log(videoname);
  const [showWatermark, setShowWatermark] = useState(false);
  const [binaryVideoData, setBinaryVideoData] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processTime, setProcessTime] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [videoFile, setVideoFile] = useState("");
  const [textName, setTextName] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [qualification, setQualification] = useState("");
  const [city, setCity] = useState("");

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 640);


  const [backgroundOption, setBackgroundOption] = useState("withoutBackground");
  const [subtitleEnabled, setSubtitleEnabled] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);

  const [selectedOption, setSelectedOption] = useState('');


  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const notify = () =>
    toast.success("Doctor Image Added!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const styles = {
    wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "60vh",
    },
    loaderContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "80px", // Match the Oval loader's size
      height: "80px", // Match the Oval loader's size
    },
    text: {
      position: "absolute",
      margin: 0,
      fontSize: "1em", // Adjust font size as needed
      color: "white", // Change text color if needed
    },
  };

  const navigate = useNavigate();

  const failed = () => {
    toast.error("Please Check the Fields or Add Video!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const charLimit = 19;
  const charLimit2 = 19;
  const charLimit3 = 22;

  const handleInputTextChange1 = (e) => {
    const value = e.target.value;

    if (value.length <= charLimit) {
      setDoctorName(e.target.value);
    }
  };

  const handleInputTextChange2 = (e) => {
    const value = e.target.value;

    if (value.length <= charLimit2) {
      setQualification(e.target.value);
    }
  };


  const handleInputTextChange3 = (e) => {
    const value = e.target.value;

    if (value.length <= charLimit3) {
      setCity(e.target.value);
    }
  };

  const remainingCharacters1 = Math.max(0, charLimit - doctorName.length);
  const remainingCharacters2 = Math.max(0, charLimit2 - qualification.length);
  const remainingCharacters3 = Math.max(0, charLimit3 - city.length);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const incrementProgress = (setter, start, end, interval) => {
    let progress = start;
    const step = 5; // Increment by 5%
    const increment = () => {
      progress += step;
      if (progress >= end) {
        progress = end;
      }
      setter(progress);
      if (progress < end) {
        setTimeout(increment, interval);
      }
    };
    increment();
  };

  
  const handleDownloadClick = async () => {
    // Check if the required fields are provided
    if (!doctorName) {
      toast.error("Please enter a text name.");
      return;
    }
  
    if (!videoFile) {
      toast.error("Please select a video file.");
      return;
    }
  
    // Show the success toast
    toast.success("You will receive the video on your email shortly.");
  
    // Delay the navigation to ensure the toast is shown
    setTimeout(() => {
      navigate(`/home/${MRID}`);
    }, 5000); // Adjust the delay time as needed
  
    const formData = new FormData();
    formData.append("doctorName", doctorName);
    formData.append("qualification", qualification);
    formData.append("city", city);
    formData.append("fileName", videoFile);
    formData.append("subtitleEnabled", subtitleEnabled);
    formData.append("backgroundOption", backgroundOption);
    formData.append("emailId", EmailId);
  

    // Check if 'withBackground' option is selected and a background image is uploaded
  if (backgroundOption === "withBackground" && backgroundImage) {
    formData.append("backgroundImage", backgroundImage); // Append the image file
  }

    if (subtitleEnabled) {
      formData.append("pain",selectedOption)
    }
  

    console.log("formdata", [...formData]);
  
    try {
      setDownloadProgress(0);
      setIsProcessing(true);
      setLoading(true);
  
      // Make the API request to process the video
      const response = await axios.post(
        `https://dummy.digilateral.com/api/auth/auth/process-videoCleaning/${MRID}/${ObjID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob", // Optional if the response needs to be downloaded
        }
      );
  
      if (response.status !== 200) {
        throw new Error(`Server responded with status: ${response.status}`);
      }
  
      // Set progress to 100% on successful response
      setDownloadProgress(100);
  
    } catch (error) {
      // Handle errors during the request
      setLoading(false);
      setDownloadProgress(0);
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsProcessing(false);
      setLoading(false);
    }
  };
  
  const handleBackgroundImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // You can perform additional checks on the file here if needed
      setBackgroundImage(file); // Assuming you want to store this file in a state
      console.log("Selected background image:", file.name);
    }
  };
  

	{/* 
  const handleVideoChange = async (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      const videoFile = selectedFiles[0];

      const video = document.createElement("video");

      video.onloadedmetadata = () => {
        // Check if the duration is exactly 10 seconds (or within a small tolerance, if needed)
        const duration = video.duration;
        // console.log('duration',duration)

        if (Math.abs(duration - 3) < 0.1) {
          // A tolerance of 0.1 seconds can be used for precision issues
          setVideoFile(videoFile);
          console.log("Video selected:", videoFile);
        } else {
          alert("Please select a video that is exactly 92 seconds long.");
          // Reset the file input value to clear the selection
          e.target.value = null;
        }

        // Revoke the object URL to free up memory
        URL.revokeObjectURL(video.src);
      };

      // Handle the case where the metadata loading fails
      video.onerror = () => {
        alert("Error loading the video file. Please try a different file.");
        e.target.value = null;
      };

      // Set the src to the selected video file to trigger metadata loading
      video.src = URL.createObjectURL(videoFile);
    }
  };
*/}


const handleVideoChange = async (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      const videoFile = selectedFiles[0];
      const video = document.createElement("video");
 
      video.onloadedmetadata = () => {
        const duration = video.duration;
 
        // Check if the duration is between 3 and 60 seconds
        if (duration >= 3 && duration <= 60) {
          setVideoFile(videoFile);
          console.log("Video selected:", videoFile);
        } else {
          alert("Please select a video that is between 3 and 60 seconds long.");
          // Reset the file input value to clear the selection
          e.target.value = null;
        }
 
        // Revoke the object URL to free up memory
        URL.revokeObjectURL(video.src);
      };
 
      // Handle the case where the metadata loading fails
      video.onerror = () => {
        alert("Error loading the video file. Please try a different file.");
        e.target.value = null;
      };
 
      // Set the src to the selected video file to trigger metadata loading
      video.src = URL.createObjectURL(videoFile);
    }
  };


  useEffect(() => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    return () => {
      document.removeEventListener("contextmenu", (event) =>
        event.preventDefault()
      );
    };
  }, []);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    console.log("Selected option:", event.target.value); // Action on change
  };

  return (
    <>
      <div className="flex justify-center items-center flex-col overflow-x-hidden ">
        <div className="w-[100vw]">
          <Sidebar />
        </div>

        <div className="absolute top-[34px] w-[335px]  sm:w-[650px] lg:w-[700px] xl:left-[450px] lg:left-[150px] sm:left-[55px]   ">
          <div className=" w-full">
            <div className="flex flex-row justify-between items-center relative top-[-8px] ">
              <NavLink
                className="p-5 flex relative left-[-70px] "
                to={`/home/${MRID}`}
              >
                <div
                  style={{ backgroundColor: "#F58420", color: "white" }}
                  className="p-[12px] drop-shadow-lg    h-10 w-10   rounded-full relative  top-[-4px] left-[17px] sm:left-[20px]  "
                >
                  <IoMdArrowRoundBack />
                </div>
              </NavLink>
              <div className="text-black text-[20px] font-bold relative left-[-60px] lg:left-[-320px] sm:left-[-270px] top-[-2px]  ">
                <p>DocSpeak Campaign</p>
              </div>
            </div>
            <div className="font-bold text-[11px] top-[-34px] relative left-[105px] sm:left-[251px] w-[250px]   sm:text-center   text-[rgba(158,156,158,1)]">
              <p></p>
            </div>
          </div>

          <div className="">
            <div className="w-[335px]  relative sm:w-auto top-[-30px]">
              <div>
              <div className="flex items-center justify-center h-[300px] bg-black">
  <img className="w-[300px] h-[300px] object-contain" src={Image9} alt="Image9" />
</div>


                <br />
                <div className="w-full relative   ">
                  <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left">
                    <label className="">Doctor Name*</label>
                  </div>

                  <input
                    type="text"
                    className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[10px] sm:left-[10px] "
                    value={doctorName}
                    onChange={handleInputTextChange1}
                    placeholder="Enter Doctor Name(without Dr. prefix)"
                  />

                  <div className="text-sm text-gray-500 flex justify-end mr-[10px]">
                    {remainingCharacters1} of {charLimit}
                  </div>

                  <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left">
                    <label className="">Qualification*</label>
                  </div>

                  <input
                    type="text"
                    className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[10px] sm:left-[10px] "
                    value={qualification}
                    onChange={handleInputTextChange2}
                    placeholder="Enter Qualification"
                  />

                  <div className="text-sm text-gray-500 flex justify-end mr-[10px]">
                    {remainingCharacters2} of {charLimit2}
                  </div>

                  <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left">
                    <label className="">Speciality*</label>
                  </div>

                  <input
                    type="text"
                    className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[10px] sm:left-[10px] "
                    value={city}
                    onChange={handleInputTextChange3}
                    placeholder="Enter City"
                  />

                  <div className="text-sm text-gray-500 flex justify-end mr-[10px]">
                    {remainingCharacters3} of {charLimit3}
                  </div>

                  

                  <div
                    className="relative left-[10px] w-full  mb-[10px]"
                    style={{ display: "inline-block", marginRight: "10px" }}
                  >
                    <input
                      type="file"
                      accept="video/mp4"
                      onChange={(e) => handleVideoChange(e)}
                    />

                    <p
                      class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                      id="file_input_help"
                    >
                      {" "}
                      MP4
                    </p>
                  </div>

                   {/* Radio buttons for custom background image selection */}
                   {/* <div className="w-[300px]  sm:top-[-10px] relative sm:left-[20px] left-[10px] text-left">
                    <label className="">Background Image*</label>
                  </div> */}
    {/* <div className="relative left-[10px] mb-4 sm:left-[20px]">
      <label className="mr-4">
        <input
          type="radio"
          name="backgroundOption"
          value="withoutBackground"
          checked={backgroundOption === "withoutBackground"}
          onChange={() => setBackgroundOption("withoutBackground")}
          className="mr-2"
        />
        Without Custom Background Image
      </label>

      <label className="mr-4">
        <input
          type="radio"
          name="backgroundOption"
          value="withBackground"
          checked={backgroundOption === "withBackground"}
          onChange={() => setBackgroundOption("withBackground")}
          className="mr-2"
        />
        With Custom Background Image
      </label>

      {/* File input for custom background image if 'With Custom Background Image' is selected */}
      {/* {backgroundOption === "withBackground" && (
        <div className="mt-2">
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleBackgroundImageChange(e)}
          />
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
            Upload a custom background image
          </p>
        </div>
      )}
    </div> */}

    {/* Subtitle toggle button */}
    
    <div className="relative left-[10px] mb-4 flex items-center">
  <label className="">Subtitle*</label>
  <div
    onClick={() => setSubtitleEnabled(!subtitleEnabled)}
    className={`relative w-[70px] h-[30px] sm:top-[40px] sm:left-[-50px] top-[35px] left-[-60px]  rounded-full cursor-pointer transition-colors duration-300 flex items-center px-2 ${
      subtitleEnabled ? "bg-green-500" : "bg-gray-300"
    }`}
  >
    {/* "On" and "Off" labels */}
    <span
      className={`absolute left-2 text-xs font-semibold ${
        subtitleEnabled ? "text-white" : "text-gray-400"
      }`}
    >
      On
    </span>
    <span
      className={`absolute right-2 text-xs font-semibold ${
        !subtitleEnabled ? "text-white" : "text-gray-400"
      }`}
    >
      Off
    </span>
    
    {/* Toggle circle */}
    <div
      className={`absolute top-[2px] w-[26px] h-[26px] rounded-full bg-white transition-transform duration-300 transform ${
        subtitleEnabled ? "translate-x-[40px]" : ""
      }`} 
    ></div>
                    </div>
</div>
                  {subtitleEnabled && <div className="mt-12 w-64">
      <label htmlFor="dropdown" className="block text-sm font-medium text-gray-700 mb-2">
        Choose an option:
      </label>
      <select
        id="dropdown"
        value={selectedOption}
        onChange={handleChange}
        className="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-gray-700"
      >
        <option value="" disabled>
          Select an option
        </option>
        <option value="script1">Pain 1</option>
        <option value="script2">Pain 2</option>
        <option value="script3">Pain 3</option>
        <option value="script4">Pain 4</option>
        <option value="script5">Pain 5</option>
        <option value="script6">Pain 6</option>
        <option value="script7">Pain 7</option>
        <option value="script8">Pain 8</option>
        <option value="script9">Pain 9</option>
        <option value="script10">Pain 10</option>
      </select>
    </div>}



                  <br />
                  <ToastContainer />
                  <button
                    onClick={handleDownloadClick}
                    disabled={isProcessing}
                    className="bg-green-500  text-white mt-[20px] rounded-full  w-full h-[30px]"
                  >
                    {isProcessing ? "Processing..." : "Download"}
                  </button>
                </div>
              </div>

              {/* <div style={styles.wrapper}>
                  <div style={styles.loaderContainer}>
                    <Oval
                      visible={true}
                      height="80"
                      width="80"
                      color="violet"
                      secondaryColor="violet"
                      ariaLabel="oval-loading"
                    />
                    <h4 style={styles.text}>{downloadProgress}%</h4>
                  </div>
                </div> */}
              {loading ? (
                <ReactModal
                  isOpen={modalIsOpen}
                  // onRequestClose={() => setModalIsOpen(false)}
                  style={{
                    overlay: {
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                    },
                    content: {
                      // top: "50%",
                      // left: "53%",
                      // right: "auto",
                      // bottom: "auto",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // marginRight: "-4%",
                      marginRight: isSmallScreen ? "0px" : "-4%",
                      // transform: "translate(-50%, -50%)",
                      borderRadius: "10px",
                      // padding: "20px",
                      textAlign: "center",
                      backgroundColor: "transparent",
                      border: "none",
                      boxShadow: "none",
                    },
                  }}
                  contentLabel="Processing Modal"
                >
                  <div style={styles.wrapper}>
                    <div style={styles.loaderContainer}>
                      <Oval
                        visible={true}
                        height="80"
                        width="80"
                        color="orange"
                        secondaryColor="orange"
                        ariaLabel="oval-loading"
                      />
                      <h4 style={styles.text}>{downloadProgress}%</h4>
                    </div>
                  </div>
                </ReactModal>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      {/* // new Ui  */}
    </>
  );
};

export default VideoCleaning;


