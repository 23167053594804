import React from "react";
import childrenThumb from "./assets/ChildrensDay_Thumbnail.jpg";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";

export const ChildrensDayThumb = () => {
  const MRID = localStorage.getItem("mrID");
  const navigate = useNavigate();

  return (
    <div className="flex flex-col md:flex-row min-h-screen lg:ml-[10rem]">
      <Sidebar />
      <div className="flex flex-col items-center justify-center flex-grow px-4 md:px-16 lg:px-32 py-8">
        <div className="text-black text-lg sm:text-xl md:text-2xl font-bold mb-4 text-center">
          <p>Video Card</p>
        </div>

        <div
          onClick={() => navigate(`/temp11/${MRID}`)}
          className="bg-white shadow-lg rounded-2xl overflow-hidden w-64 sm:w-80 cursor-pointer transition-transform transform hover:scale-105"
        >
          <div className="w-full h-[200px] sm:h-[250px] lg:h-[270px] flex items-center justify-center">
            <img
              src={childrenThumb}
              alt="Children's Day"
              className="w-[250px] h-full"
            />
          </div>
          <div className="p-4 text-center">
            <h2 className="text-lg sm:text-xl font-semibold text-gray-800">Children's Day</h2>
          </div>
        </div>
      </div>
    </div>
  );
};
