import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import ReactModal from "react-modal";
import { Oval } from "react-loader-spinner";
import Sidebar from "./Sidebar/Sidebar";
import { IoMdArrowRoundBack } from "react-icons/io";
import CropFun from "./Crop/CropFun";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Template1.css";
import Image4 from "../components/assets/Techer_Thumbnails.png";

const TeacherDay = () => {
  const { MRID } = useParams();
  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [processTime, setProcessTime] = useState("");

  const navigate = useNavigate();
  const EmailId = localStorage.getItem("EmailId");
  const ObjID = localStorage.getItem("mrID");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [doctorName, setDoctorName] = useState("");
  const [qualification, setQualification] = useState("");
  const [city, setCity] = useState("");
  const [docImage, setDocImg] = useState("");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 640);

  const charLimit = 22;
  const charLimitCity = 22;

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const remainingCharacters1 = Math.max(0, charLimit - doctorName.length);
  const remainingCharacters2 = Math.max(0, charLimitCity - city.length);
  const remainingCharacters3 = Math.max(0, charLimit - qualification.length);

  const handleInputTextChange1 = (e) => {
    const value = e.target.value;
    if (value.length <= charLimit) {
      setDoctorName(e.target.value);
    }
  };

  const handleInputTextChange2 = (e) => {
    const value = e.target.value;
    if (value.length <= charLimitCity) {
      setCity(e.target.value);
    }
  };

  const handleInputTextChange3 = (e) => {
    const value = e.target.value;
    if (value.length <= charLimit) {
      setQualification(e.target.value);
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  
  const handleImageChange = (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      const imageFile = selectedFiles[0];
      const img = new Image();

      img.onload = () => {
        if (img.width === 390 && img.height === 390) {
          const reader = new FileReader();
          reader.onload = (event) => {
            setDocImg(imageFile);
          };
          reader.readAsDataURL(imageFile);
        } else {
          toast.error("Please select an image that is exactly 390x390 pixels.");
          e.target.value = null;
        }
      };

      img.onerror = () => {
        toast.error(
          "Error loading the image file. Please try a different file."
        );
        e.target.value = null;
      };

      img.src = URL.createObjectURL(imageFile);
    }
  };

  // const handleImageChange = (e) => {
  //   const selectedFiles = e.target.files;
  //   if (selectedFiles.length > 0) {
  //     const imageFile = selectedFiles[0];
  //     const img = new Image();

  //     img.onload = () => {
  //       // Create a canvas element to resize the image
  //       const canvas = document.createElement('canvas');
  //       const ctx = canvas.getContext('2d');
  //       canvas.width = 390;
  //       canvas.height = 390;

  //       // Draw the image onto the canvas, resizing it to 390x390
  //       ctx.drawImage(img, 0, 0, 390, 390);

  //       // Convert the canvas to a Blob or Data URL and save it
  //       canvas.toBlob((blob) => {
  //         const resizedImageFile = new File([blob], imageFile.name, {
  //           type: imageFile.type,
  //           lastModified: Date.now(),
  //         });

  //         setDocImg(resizedImageFile); // Update your state with the resized image
  //       }, imageFile.type);
  //     };

  //     img.onerror = () => {
  //       toast.error("Error loading the image file. Please try a different file.");
  //       e.target.value = null;
  //     };

  //     img.src = URL.createObjectURL(imageFile);
  //   }
  // };

  const handleDownloadClick = async () => {
    if (!doctorName || !qualification || !city || !docImage) {
      toast.error("Please fill all the required fields.");
      return;
    }

    toast.success("Your video will be available for download in Processed Videos and via email (if set)");
    setTimeout(() => {
      navigate(`/home/${MRID}`);
    }, 5000);

    const formData = new FormData();
    formData.append("doctorName", doctorName);
    formData.append("qualification", qualification);
    formData.append("city", city);
    formData.append("doctorImage", docImage);
    formData.append("emailId", EmailId);

    try {
      setDownloadProgress(0);
      setIsProcessing(true);
      setLoading(true);

      const startTime = new Date().getTime();
      const response = await axios.post(
        `https://dummy.digilateral.com/api/auth/auth/process-teacher-gold/${MRID}/${ObjID}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob",
        }
      );

      if (response.status !== 200) {
        throw new Error(`Server responded with status: ${response.status}`);
      }

      const endTime = new Date().getTime();
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsProcessing(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    return () => {
      document.removeEventListener("contextmenu", (event) =>
        event.preventDefault()
      );
    };
  }, []);

  return (
    <>
      <div className="flex justify-center items-center flex-col overflow-x-hidden">
        <div className="w-[100vw]">
          <Sidebar />
        </div>

        <div className="absolute top-[34px] w-[335px] sm:w-[650px] lg:w-[700px] xl:left-[450px] lg:left-[150px] sm:left-[55px]">
          <div className="w-full">
            <div className="flex flex-row justify-between items-center relative top-[-8px]">
              <NavLink
                className="p-5 flex relative left-[-36px]"
                to={`/home/${MRID}`}
              >
                <div
                  style={{ backgroundColor: "#F58420", color: "white" }}
                  className="p-[12px] drop-shadow-lg h-10 w-10 rounded-full relative top-[-4px] left-[14px] sm:left-[20px]"
                >
                  <IoMdArrowRoundBack />
                </div>
              </NavLink>

              <div className="text-black text-[20px] font-bold relative left-[-90px] lg:left-[-270px] sm:left-[-270px] top-[5px]">
                <p>Teachers Day</p>
              </div>
            </div>
          </div>

          <div className="">
            <div className="w-[335px] border-2 relative sm:w-auto top-[-30px]">
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Crop Modal"
              >
                <CropFun />
                <div className="absolute top-[20px] right-0 bg-orange-500 p-[12px] drop-shadow-lg h-10 w-10 rounded-full">
                  <button onClick={closeModal}>
                    <FaTimes color={"#fff"} />
                  </button>
                </div>
              </Modal>

              <div className="mt-5 relative">
                <div>
                  <img width="900" src={Image4} alt="Image1" />
                </div>

                <div className="flex justify-center items-center flex-col">
                  <p className="font-bold text-[15px] text-[#000000] relative sm:top-[10px]">
                    Doctor's Information
                  </p>

                  <div className="w-full relative">
                    <div>
                      <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left">
                        <label className="">Doctor Name*</label>
                      </div>

                      <input
                        type="text"
                        className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[10px] sm:left-[10px] "
                        value={doctorName}
                        onChange={handleInputTextChange1}
                        placeholder="Enter Doctor Name(without Dr. prefix)"
                      />

                      <div className="text-sm text-gray-500 flex justify-end mr-[10px]">
                        {remainingCharacters1} of {charLimit}
                      </div>

                      <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left">
                        <label className="">Qualification*</label>
                      </div>

                      <input
                        type="text"
                        className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[10px] sm:left-[10px] "
                        value={qualification}
                        onChange={handleInputTextChange3}
                        placeholder="Enter Your Qualification"
                      />

                      <div className="text-sm text-gray-500 flex justify-end mr-[10px]">
                        {remainingCharacters3} of {charLimit}
                      </div>

                      <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left">
                        <label className="">City*</label>
                      </div>

                      <input
                        type="text"
                        className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[10px] sm:left-[10px] "
                        value={city}
                        onChange={handleInputTextChange2}
                        placeholder="Enter City Name"
                      />

                      <div className="text-sm text-gray-500 flex justify-end mr-[10px]">
                        {remainingCharacters2} of {charLimitCity}
                      </div>

                      <div
                        className="relative left-[10px] w-full  mb-[10px]"
                        style={{ display: "inline-block", marginRight: "10px" }}
                      >
                        <div className="w-[300px]  relative mb-2 mt-2 sm:left-[5px] left-[2px] text-left">
                          <label className="">Select Doctor Image*</label>
                        </div>

                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleImageChange(e)}
                        />

                        <p
                          class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                          id="file_input_help"
                        >
                          {" "}
                          select PNG, JPG with exactly 390*390.
                        </p>
                      </div>
                      <div className="relative sm:top-[-1px] ">
                      <button
                            onClick={openModal}
                            className="bg-[#afaeae] text-black  rounded-full  transition duration-300 w-full h-[40px]"
                          >
                            Crop
                          </button>
                      </div>
                      
                    </div>

                    <div className="relative sm:top-[0px] flex justify-center items-center mt-4">
                    <button
                      onClick={handleDownloadClick}
                      className={`${isProcessing ? 'bg-gray-400 text-white mt-[20px] rounded-full  w-full h-[30px]' : "bg-green-500  text-white mt-[20px] rounded-full  w-full h-[40px]"}`}
                      disabled={isProcessing}
                    >
                      {isProcessing ? "Generating Your Video Please Wait" : "Download"}
                    </button> 
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ToastContainer />
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherDay;
