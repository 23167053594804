//Template1  = Video Happy Doctor Day

import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import ReactModal from "react-modal";
import { Oval } from "react-loader-spinner";
import Sidebar from "./Sidebar/Sidebar";
import { IoMdArrowRoundBack } from "react-icons/io";
import CropFun from "./Crop/CropFun";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Template1.css";
import Image4 from "../components/assets/teacher_thumb.png";

const Template4 = () => {
  const { videoname, MRID } = useParams();
  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [yellowImage, setTeacherImg] = useState("");
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [greenImage, setDoctorImg] = useState("");
  const [processTime, setProcessTime] = useState("");

  const navigate = useNavigate();

  const notify = () =>
    toast.success("Doctor Image Added!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const failed = () => {
    toast.error("Please Check the Fields or Add Image!", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const downloadcomplete = () =>
    toast.success("Video Processed !", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const styles = {
    wrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "60vh",
    },
    loaderContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "80px", // Match the Oval loader's size
      height: "80px", // Match the Oval loader's size
    },
    text: {
      position: "absolute",
      margin: 0,
      fontSize: "1em", // Adjust font size as needed
      color: "white", // Change text color if needed
    },
  };

  const charLimit = 19;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [modalIsOpen1, setModalIsOpen1] = useState(false);

  const [textFrom, setTextFrom] = useState("");
  const [textTo, setTextTo] = useState("");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 640);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //MRID Get form localstorage...
  const mrId = localStorage.getItem("mrID");

  const handleInputTextChange1 = (e) => {
    const value = e.target.value;

    if (value.length <= charLimit) {
      setTextFrom(e.target.value);
    }
  };

  const remainingCharacters1 = Math.max(0, charLimit - textFrom.length);

  const handleInputTextChange2 = (e) => {
    const value = e.target.value;

    if (value.length <= charLimit) {
      setTextTo(e.target.value);
    }
  };

  const remainingCharacters2 = Math.max(0, charLimit - textTo.length);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleImageChange = (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      const imageFile = selectedFiles[0];
      const img = new Image();

      img.onload = () => {
        // Check if the dimensions are exactly 380x380
        if (img.width === 390 && img.height === 390) {
          const reader = new FileReader();
          reader.onload = (event) => {
            setTeacherImg(imageFile);
          };
          reader.readAsDataURL(imageFile);
        } else {
          toast.error("Please select an image that is exactly 380x380 pixels.");
          e.target.value = null;
        }
      };

      // Handle the case where the image loading fails
      img.onerror = () => {
        alert("Error loading the image file. Please try a different file.");
        e.target.value = null;
      };

      // Set the src to the selected image file to trigger the onload event
      img.src = URL.createObjectURL(imageFile);
    }
  };

  const handleImageChange2 = (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 0) {
      const imageFile = selectedFiles[0];
      const img = new Image();

      img.onload = () => {
        // Check if the dimensions are exactly 380x380
        if (img.width === 390 && img.height === 390) {
          const reader = new FileReader();
          reader.onload = (event) => {
            setDoctorImg(imageFile);
          };
          reader.readAsDataURL(imageFile);
        } else {
          toast.error("Please select an image that is exactly 380x380 pixels.");
          e.target.value = null;
        }
      };

      // Handle the case where the image loading fails
      img.onerror = () => {
        alert("Error loading the image file. Please try a different file.");
        e.target.value = null;
      };

      // Set the src to the selected image file to trigger the onload event
      img.src = URL.createObjectURL(imageFile);
    }
  };

  // const handleDownloadClick = async () => {
  //   if(!textFrom){
  //     toast.error("Please type Teacher Name");
  //     return;
  //   }

  //   if (!yellowImage) {
  //     toast.error("Please select a Teacher Image.");
  //     return;
  //   }

  //   if(!textTo){
  //     toast.error("Please type Doctor Name");
  //     return;
  //   }

  //   if (!greenImage) {
  //     toast.error("Please enter a Doctor Image.");
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append("yellowImage", yellowImage);
  //   formData.append("greenImage", greenImage);
  //   formData.append("textFrom", textFrom);
  //   formData.append("textTo", textTo);

  //   try {
  //     setDownloadProgress(0);
  //     setIsProcessing(true);
  //     setLoading(true);
  //     setModalIsOpen1(true);

  //     const startTime = new Date().getTime();
  //     const maxTime = 16 * 60 * 1000; // 16 minutes in milliseconds
  //     let currentPercentage = 0;

  //     // First interval for 5% increments up to 70%
  //     const interval1 = setInterval(() => {
  //       currentPercentage += 5;
  //       if (currentPercentage >= 70) {
  //         currentPercentage = 70;
  //         clearInterval(interval1);
  //         // Start second interval for 2% increments up to 90%
  //         const interval2 = setInterval(() => {
  //           currentPercentage += 2;
  //           if (currentPercentage >= 90) {
  //             currentPercentage = 90;
  //             clearInterval(interval2);
  //           }
  //           setDownloadProgress(currentPercentage);
  //         }, 4000); // 4 seconds
  //       }
  //       setDownloadProgress(currentPercentage);
  //     }, 15000); // 7 seconds

  //     const response = await axios.post(
  //       "https://dummy.digilateral.com/api/auth/auth/process-video-teacher",
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //         responseType: "blob",
  //       }
  //     );

  //     if (response.status !== 200) {
  //       setLoading(false);
  //       navigate(`/home/${MRID}`);
  //       throw new Error(`Server responded with status: ${response.status}`);
  //     }

  //     const endTime = new Date().getTime();
  //     const processingTime = ((endTime - startTime) / 1000).toFixed(2); // Convert to seconds and round
  //     console.log(`Processing time: ${processingTime} seconds`);
  //     setProcessTime(processingTime);

  //     setDownloadProgress(100); // Set progress to 100%

  //     setTimeout(() => {
  //       navigate(`/home/${MRID}`);
  //       setLoading(false);
  //     }, 1400);

  //     const blob = new Blob([response.data], { type: response.data.type });
  //     const fileSizeMB = (blob.size / (1024 * 1024)).toFixed(2);
  //     console.log(`File size: ${fileSizeMB} MB`);
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = `Dr. ${textName}.mp4`;
  //     a.click();
  //     URL.revokeObjectURL(url);

  //     // Send usage data
  //     // const userData = await axios.post(
  //     //   `https://dummy.digilateral.com/api/auth/auth/submitUsage/${MRID}`,
  //     //   {
  //     //     type: "videocard",
  //     //     doctorName: textName,
  //     //     videoname: name,
  //     //     fileName: `Dr. ${textName}.mp4`,
  //     //     processTime: `${processingTime}s`,
  //     //     MRID: MRID,
  //     //     MBSIZE: fileSizeMB,
  //     //     status: response.status === 200 ? "success" : "failed",
  //     //   }
  //     // );
  //   } catch (error) {
  //     setLoading(false);
  //     setDownloadProgress(0); // Optionally reset progress to 0
  //     setTimeout(() => {
  //       navigate(`/home/${MRID}`);
  //       setLoading(false);
  //     }, 1400);
  //     toast.error(`Error: ${error.message}`);
  //   } finally {
  //     setIsProcessing(false);
  //   }
  // };

  // const handleDownloadClick = async () => {
  //   if (!textFrom) {
  //     toast.error("Please type Teacher Name");
  //     return;
  //   }

  //   if (!yellowImage) {
  //     toast.error("Please select a Teacher Image.");
  //     return;
  //   }

  //   if (!textTo) {
  //     toast.error("Please type Doctor Name");
  //     return;
  //   }

  //   if (!greenImage) {
  //     toast.error("Please enter a Doctor Image.");
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append("yellowImage", yellowImage);
  //   formData.append("greenImage", greenImage);
  //   formData.append("textFrom", textFrom);
  //   formData.append("textTo", textTo);

  //   console.log(formData)
  //   try {
  //     setDownloadProgress(0);
  //     setIsProcessing(true);
  //     setLoading(true);
  //     setModalIsOpen1(true);

  //     const startTime = new Date().getTime();
  //     const maxTime = 16 * 60 * 1000; // 16 minutes in milliseconds
  //     let currentPercentage = 0;

  //     // First interval for 5% increments up to 70%
  //     const interval1 = setInterval(() => {
  //       currentPercentage += 5;
  //       if (currentPercentage >= 70) {
  //         currentPercentage = 70;
  //         clearInterval(interval1);

  //         // Start second interval for 2% increments up to 90%
  //         const interval2 = setInterval(() => {
  //           currentPercentage += 2;
  //           if (currentPercentage >= 90) {
  //             currentPercentage = 90;
  //             clearInterval(interval2);
  //           }
  //           setDownloadProgress(currentPercentage);
  //         }, 4000); // 4 seconds
  //       }
  //       setDownloadProgress(currentPercentage);
  //     }, 7000); // 7 seconds

  //     const response = await axios.post(
  //       "https://dummy.digilateral.com/api/auth/auth/process-video-teacher",
  //       {
  //         yellowImage: yellowImage,
  //         greenImage: greenImage,
  //         textFrom: textFrom,
  //         textTo: textTo
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //         responseType: "blob",
  //       }
  //     );

  //     clearInterval(interval1); // Ensure intervals are cleared

  //     if (response.status !== 200) {
  //       setLoading(false);
  //       navigate(`/home/${MRID}`);
  //       throw new Error(`Server responded with status: ${response.status}`);
  //     }

  //     const endTime = new Date().getTime();
  //     const processingTime = ((endTime - startTime) / 1000).toFixed(2); // Convert to seconds and round
  //     console.log(`Processing time: ${processingTime} seconds`);
  //     setProcessTime(processingTime);

  //     setDownloadProgress(100); // Set progress to 100%

  //     setTimeout(() => {
  //       navigate(`/home/${MRID}`);
  //       setLoading(false);
  //     }, 1400);

  //     const blob = new Blob([response.data], { type: response.data.type });
  //     const fileSizeMB = (blob.size / (1024 * 1024)).toFixed(2);
  //     console.log(`File size: ${fileSizeMB} MB`);
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = `Dr. ${textName}.mp4`;
  //     a.click();
  //     URL.revokeObjectURL(url);

  //     // Send usage data
  //     // const userData = await axios.post(
  //     //   `https://dummy.digilateral.com/api/auth/auth/submitUsage/${MRID}`,
  //     //   {
  //     //     type: "videocard",
  //     //     doctorName: textName,
  //     //     videoname: name,
  //     //     fileName: `Dr. ${textName}.mp4`,
  //     //     processTime: `${processingTime}s`,
  //     //     MRID: MRID,
  //     //     MBSIZE: fileSizeMB,
  //     //     status: response.status === 200 ? "success" : "failed",
  //     //   }
  //     // );
  //   } catch (error) {
  //     setLoading(false);
  //     setDownloadProgress(0); // Optionally reset progress to 0
  //     setTimeout(() => {
  //       navigate(`/home/${MRID}`);
  //       setLoading(false);
  //     }, 1400);
  //     toast.error(`Error: ${error.message}`);
  //   } finally {
  //     setIsProcessing(false);
  //   }
  // };

  const handleDownloadClick = async () => {
    if (!textFrom) {
      toast.error("Please type Teacher Name");
      return;
    }

    if (!yellowImage) {
      toast.error("Please select a Teacher Image.");
      return;
    }

    if (!textTo) {
      toast.error("Please type Doctor Name");
      return;
    }

    if (!greenImage) {
      toast.error("Please enter a Doctor Image.");
      return;
    }

    const formData = new FormData();
    formData.append("yellowImage", yellowImage);
    formData.append("greenImage", greenImage);
    formData.append("textFrom", textFrom);
    formData.append("textTo", textTo);

    console.log("formdata", [...formData]);

    try {
      setDownloadProgress(0);
      setIsProcessing(true);
      setLoading(true);
      setModalIsOpen1(true);

      const startTime = new Date().getTime();
      const maxTime = 16 * 60 * 1000; // 16 minutes in milliseconds
      let currentPercentage = 0;

      // First interval for 5% increments up to 70%
      const interval1 = setInterval(() => {
        currentPercentage += 5;
        if (currentPercentage >= 70) {
          currentPercentage = 70;
          clearInterval(interval1);

          // Start second interval for 2% increments up to 90%
          const interval2 = setInterval(() => {
            currentPercentage += 2;
            if (currentPercentage >= 90) {
              currentPercentage = 90;
              clearInterval(interval2);
            }
            setDownloadProgress(currentPercentage);
          }, 4000); // 4 seconds
        }
        setDownloadProgress(currentPercentage);
      }, 7000); // 7 seconds

      const response = await axios.post(
        "https://dummy.digilateral.com/api/auth/auth/process-video-teacher",
        formData, // Use FormData object directly
        {
          headers: {
            "Content-Type": "multipart/form-data", // Let the browser set the correct Content-Type
          },
          responseType: "blob",
        }
      );

      clearInterval(interval1); // Ensure intervals are cleared

      if (response.status !== 200) {
        setLoading(false);
        navigate(`/home/${MRID}`);
        throw new Error(`Server responded with status: ${response.status}`);
      }

      const endTime = new Date().getTime();
      const processingTime = ((endTime - startTime) / 1000).toFixed(2); // Convert to seconds and round
      console.log(`Processing time: ${processingTime} seconds`);
      setProcessTime(processingTime);

      setDownloadProgress(100); // Set progress to 100%

      setTimeout(() => {
        navigate(`/home/${MRID}`);
        setLoading(false);
      }, 1400);

      const blob = new Blob([response.data], { type: response.data.type });
      const fileSizeMB = (blob.size / (1024 * 1024)).toFixed(2);
      console.log(`File size: ${fileSizeMB} MB`);
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Dr. ${textFrom}.mp4`;
      a.click();
      URL.revokeObjectURL(url);

      console.log("blob", blob);

      // Send usage data
      // const userData = await axios.post(
      //   `https://dummy.digilateral.com/api/auth/auth/submitUsage/${MRID}`,
      //   {
      //     type: "videocard",
      //     doctorName: textName,
      //     videoname: name,
      //     fileName: `Dr. ${textName}.mp4`,
      //     processTime: `${processingTime}s`,
      //     MRID: MRID,
      //     MBSIZE: fileSizeMB,
      //     status: response.status === 200 ? "success" : "failed",
      //   }
      // );
    } catch (error) {
      setLoading(false);
      setDownloadProgress(0); // Optionally reset progress to 0
      setTimeout(() => {
        navigate(`/home/${MRID}`);
        setLoading(false);
      }, 1400);
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    return () => {
      document.removeEventListener("contextmenu", (event) =>
        event.preventDefault()
      );
    };
  }, []);

  return (
    <>
      <div className="flex justify-center items-center flex-col overflow-x-hidden ">
        <div className="w-[100vw]">
          <Sidebar />
        </div>

        <div className="absolute top-[34px] w-[335px]  sm:w-[650px] lg:w-[700px] xl:left-[450px] lg:left-[150px] sm:left-[55px]   ">
          <div className=" w-full">
            <div className="flex flex-row justify-between items-center relative top-[-8px] ">
              <NavLink
                className="p-5 flex relative left-[-36px] "
                to={`/home/${MRID}`}
              >
                <div
                  style={{ backgroundColor: "#F58420", color: "white" }}
                  className="p-[12px] drop-shadow-lg    h-10 w-10   rounded-full relative  top-[-4px] left-[17px] sm:left-[20px]  "
                >
                  <IoMdArrowRoundBack />
                </div>
              </NavLink>

              <div className="text-black text-[20px] font-bold relative left-[-120px] lg:left-[-320px] sm:left-[-270px] top-[-2px]  ">
                <p>Teacher's Day</p>
              </div>
            </div>
            <div className="font-bold text-[11px] top-[-34px] relative left-[105px] sm:left-[251px] w-[250px]   sm:text-center   text-[rgba(158,156,158,1)]">
              <p></p>
            </div>
          </div>

          <div className="">
            <div className="w-[335px] border-2  relative sm:w-auto top-[-30px]">
              <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Crop Modal"
              >
                <CropFun />
                {/* Close icon */}
                <div className="absolute top-[20px] right-0 bg-orange-500 p-[12px] drop-shadow-lg    h-10 w-10   rounded-full ">
                  <button onClick={closeModal} className="">
                    {" "}
                    <FaTimes color={"#fff"} />
                  </button>{" "}
                </div>
              </Modal>

              {/* Video 1 */}

              <div>
                <img width="900" src={Image4} alt="Image1" />
              </div>

              <br />

              <div className="w-full relative   ">
                <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left">
                  <label className="">Teacher Name & Image*</label>
                </div>

                <input
                  type="text"
                  className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[10px] sm:left-[10px] "
                  value={textFrom}
                  onChange={handleInputTextChange1}
                  placeholder="Enter Teacher Name(without Dr. prefix)"
                />

                <div className="text-sm text-gray-500 flex justify-end mr-[10px]">
                  {remainingCharacters1} of {charLimit}
                </div>

                <div
                  className="relative left-[10px] w-full  mb-[10px]"
                  style={{ display: "inline-block", marginRight: "10px" }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e)}
                  />

                  <p
                    class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                    id="file_input_help"
                  >
                    {" "}
                    select PNG, JPG with exactly 380*380.
                  </p>
                </div>

                <div className="w-[300px]  relative sm:left-[20px] left-[10px] text-left">
                  <label className="">Doctor Name & Image*</label>
                </div>

                <input
                  type="text"
                  className="w-[96%] mb-[10px] h-[40px] border-2 rounded-xl p-[10px] top-[10px] relative sm:top-[10px] sm:left-[10px] "
                  value={textTo}
                  onChange={handleInputTextChange2}
                  placeholder="Enter Teacher Name(without Dr. prefix)"
                />

                <div className="text-sm text-gray-500 flex justify-end mr-[10px]">
                  {remainingCharacters2} of {charLimit}
                </div>

                <div
                  className="relative left-[10px] w-full  mb-[10px]"
                  style={{ display: "inline-block", marginRight: "10px" }}
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange2(e)}
                  />

                  <p
                    class="mt-1 text-sm text-gray-500 dark:text-gray-300"
                    id="file_input_help"
                  >
                    {" "}
                    select PNG, JPG with exactly 380*380.
                  </p>
                </div>

                <br />
                <button
                  onClick={openModal}
                  className="bg-[#e5e5e5] text-black  rounded-full  transition duration-300 w-full h-[30px]"
                >
                  Crop
                </button>

                <ToastContainer />
                <button
                  onClick={handleDownloadClick}
                  className="bg-green-500  text-white mt-[20px] rounded-full  w-full h-[30px]"
                >
                  Download
                </button>
              </div>
            </div>

            {loading ? (
              <ReactModal
                isOpen={modalIsOpen1}
                // onRequestClose={() => setModalIsOpen(false)}
                style={{
                  overlay: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  },
                  content: {
                    // top: "50%",
                    // left: "53%",
                    // right: "auto",
                    // bottom: "auto",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // marginRight: "-4%",
                    marginRight: isSmallScreen ? "0px" : "-4%",
                    // transform: "translate(-50%, -50%)",
                    borderRadius: "10px",
                    // padding: "20px",
                    textAlign: "center",
                    backgroundColor: "transparent",
                    border: "none",
                    boxShadow: "none",
                  },
                }}
                contentLabel="Processing Modal"
              >
                <div style={styles.wrapper}>
                  <div style={styles.loaderContainer}>
                    <Oval
                      visible={true}
                      height="80"
                      width="80"
                      color="orange"
                      secondaryColor="orange"
                      ariaLabel="oval-loading"
                    />
                    <h4 style={styles.text}>{downloadProgress}%</h4>
                  </div>
                </div>
              </ReactModal>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {/* // new Ui  */}
    </>
  );
};

export default Template4;
