import React, { useCallback, useState } from "react";
import Image1 from "../components/assets/KidneyDay_Thumbnail.jpg";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Sidebar from "./Sidebar/Sidebar";
import { IoMdArrowRoundBack } from "react-icons/io";
import { NavLink, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import CropFun from "./Crop/CropFun";
import { FaTimes } from "react-icons/fa";
import Cropper from "react-easy-crop";

export const KidneyDay = () => {
  const [inputText1, setInputText1] = useState("");
  const [inputText2, setInputText2] = useState("");
  const [inputText3, setInputText3] = useState("");
  const [inputText4, setInputText4] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [crop, setCrop] = useState({ unit: "px", width: 30, aspect: 1 });
  const [imagePreview, setImagePreview] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [imageError, setImageError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null); // State to store the file
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];

      // Create an image object to check its dimensions
      const img = new Image();
      const objectURL = URL.createObjectURL(selectedFile);
      img.src = objectURL;

      img.onload = () => {
        const { width, height } = img;

        // Variable to store the error message
        let errorMessage = "";

        // Check if the image's dimensions are below the threshold
        if (width === 590 || height === 590) {
          errorMessage =
            "Your selected image resolution is below our required resolution. The output image might be hindered.";
        }

        setAlertMessage(errorMessage); // Set the alert message
        setImageError(errorMessage); // Save the error message to state

        // Set the selected file and preview regardless of dimensions
        setSelectedFile(selectedFile);
        setImagePreview(objectURL);
      };

      img.onerror = () => {
        const error = "Invalid image file.";
        setAlertMessage(error);
        setImageError(error); // Save the error message to state
        setSelectedFile(null);
        setImagePreview(null);
      };
    }
  };

  const getCroppedImg = async (
    imageSrc,
    crop,
    targetWidth = 590,
    targetHeight = 590
  ) => {
    const image = await new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous"; // Prevent CORS issues
      img.src = imageSrc;
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
    });

    // Create a canvas with the target dimensions
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Set the canvas size to the target dimensions
    canvas.width = targetWidth;
    canvas.height = targetHeight;

    // Draw the cropped image onto the canvas with resizing
    ctx.drawImage(
      image,
      crop.x, // Source X
      crop.y, // Source Y
      crop.width, // Source Width
      crop.height, // Source Height
      0, // Destination X
      0, // Destination Y
      targetWidth, // Destination Width
      targetHeight // Destination Height
    );

    // Convert the canvas to a Blob and return a File object
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          reject(new Error("Canvas is empty"));
          return;
        }
        // Convert Blob to File
        const file = new File([blob], `croppedImage_${Date.now()}.jpeg`, {
          type: "image/jpeg",
          lastModified: Date.now(),
        });

        resolve(file);
      }, "image/jpeg");
    });
  };

  const handleCrop = async () => {
    if (!croppedAreaPixels) return;

    try {
      const croppedImg = await getCroppedImg(imagePreview, croppedAreaPixels);
      setCroppedImage(croppedImg);
      toast.success("Image Cropped Successfully");
    } catch (error) {
      console.error("Error cropping the image:", error);
    }
  };

  const charLimit = 20;
  const MrObjId = localStorage.getItem("mrID");
  console.log("checking222", MrObjId);
  const navigate = useNavigate();

  const handleInputTextChange = (setter) => (e) => {
    if (e.target.value.length <= charLimit) {
      setter(e.target.value);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        if (img.width === 590 || img.height === 590) {
          toast.error("Image must be at least 590 x 590 pixels.");
        } else {
          setSelectedImage(file);
        }
      };
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleDownloadClick = async () => {
    if (!inputText1 || !inputText2 || !inputText3 || !inputText4) {
      toast.error("Please enter all required text fields.");
      return;
    }
    if (!croppedImage) {
      toast.error("Please select an image file.");
      return;
    }
    toast.success("Processing your request...");
    setIsProcessing(true);
    try {
      const file = croppedImage;
      const fileExtension = file.name.split(".").pop();
      const sanitizedFileName =
        `${inputText1}_${inputText2}_${inputText3}_${inputText4}`
        
      const fileName = `${sanitizedFileName}_${uuidv4()}.${fileExtension}`;
      const { data: presignedData } = await axios.post(
        "https://somprazquiz.digilateral.com/getPresignedUrlvd",
        { fileName, fileType: file.type }
      );
      const { uploadUrl, key } = presignedData;
      await axios.put(uploadUrl, file, {
        headers: { "Content-Type": file.type },
      });

      const requestBody = {
        Name: inputText1,
        Speciality: inputText2,
        Hospital: inputText3,
        City: inputText4,
        ImageFile: key,
      };

      await axios.post(
        `https://dummy.digilateral.com/api/auth/create-card/kidney/${MrObjId}`,
        requestBody,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      toast.success("Your Video will be available in processed videos shortly!");
      setIsUploaded(true); 

      setTimeout(() => {
        navigate(`/temp12-thumb/${MrObjId}`);
      }, 5000);
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="flex min-h-screen w-full overflow-x-hidden flex-col md:flex-row">
      <Sidebar />
      <div className="flex flex-col flex-1 p-4">
      <div className="flex flex-row justify-between items-center sm:mr-16 ">
          <NavLink
            className="p-5 flex relative left-[-36px] "
            to={`/temp12-thumb/${MrObjId}`}
          >
            <div
              style={{ backgroundColor: "#F58420", color: "white" }}
              className="p-[12px] drop-shadow-lg    h-10 w-10   rounded-full relative  top-[-4px] left-[17px] sm:left-[20px]  "
            >
              <IoMdArrowRoundBack />
            </div>
          </NavLink>
        </div>
        <div className="text-black text-lg font-bold text-center flex-1 md:text-xl lg:ml-28 lg:mt-[-65px] mb-[30px]">
          <p>World Kidney Day</p>
        </div>
        <div className="flex justify-center items-center lg:w-full lg:max-w-full sm:max-w-[700px] mt-[-30px] p-2 lg:ml-16">
          <div className="w-full max-w-xl bg-white p-4 shadow-lg rounded-lg">
            <div className="flex justify-center">
              <img src={Image1} alt="Kidney Day" className="w-32 sm:w-48" />
            </div>
            {[inputText1, inputText2, inputText3, inputText4].map(
              (value, index) => (
                <div key={index} className="w-full mt-4 text-left">
                  <label className="block text-gray-700 font-medium">
                    {["Name*", "Specialty*", "Hospital Name*", "City*"][index]}
                  </label>
                  <input
                    type="text"
                    className="w-full h-10 border-2 rounded-lg px-3 mt-1 focus:outline-none focus:ring-2 focus:ring-gray-300"
                    value={value}
                    onChange={handleInputTextChange(
                      [
                        setInputText1,
                        setInputText2,
                        setInputText3,
                        setInputText4,
                      ][index]
                    )}
                    maxLength={charLimit} // Prevents typing beyond the limit
                    placeholder={`Enter ${
                      ["Name", "Specialty", "Hospital Name", "City"][index]
                    }`}
                  />
                  <p className="text-sm text-gray-500 mt-1 text-end">
                    {value.length} of {charLimit}
                  </p>
                </div>
              )
            )}
            {/* <div className="w-full mt-4 text-left">
              <label className="block text-gray-700 font-medium">
                Upload Image*
              </label>
              <label className="bg-gray-300 text-black px-4 py-2 rounded cursor-pointer inline-block mt-2">
                Choose File
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="hidden"
                />
              </label>
              {selectedImage && (
                <p className="mt-2 text-gray-600 truncate">
                  Selected: {selectedImage.name}
                </p>
              )}
            </div> */}

            <div className=" border-gray-300 rounded-lg shadow-lg p-6">
              {/* Upload Section */}
              {!imagePreview && (
                <div className="flex flex-col items-center justify-center h-60 bg-gray-50 border-2 border-dashed border-gray-400 rounded-lg cursor-pointer hover:bg-gray-100 transition">
                  <input
                    id="upload"
                    type="file"
                    className="hidden"
                    accept="image/*"
                    onChange={(e) => {
                      onSelectFile(e);
                      setCrop({ x: 0, y: 0 });
                      setZoom(1); // Reset zoom to initial value when a new file is selected
                    }}
                  />
                  <label htmlFor="upload" className="text-center">
                    <div className="mb-2 text-2xl font-bold text-gray-700">
                      📂 Select Picture
                    </div>
                    <p className="text-gray-500">
                      <b className="text-gray-700">JPG, PNG</b>
                    </p>
                  </label>
                </div>
              )}

              {/* Cropper Section */}
              {imagePreview && (
                <div className="relative">
                  {/* Image Cropper */}
                  <div className="crop-container relative h-72 bg-gray-200 rounded-lg overflow-hidden border border-gray-300 shadow-inner">
                    {/* <div className="absolute top-0 left-0 h-full w-[22%] bg-gray-700 bg-opacity-50 z-10 flex items-center justify-center">
                      <span className="text-white font-semibold text-sm tracking-wide rotate-90 transform origin-center text-center">
                        Invisible Area
                      </span>
                    </div> */}

                    <Cropper
                      image={imagePreview}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                      onCropComplete={onCropComplete}
                      cropShape="rect"
                    />
                  </div>

                  {/* Zoom Slider */}
                  <div className="mt-4">
                    <input
                      type="range"
                      min="1"
                      max="3"
                      step="0.01"
                      value={zoom}
                      onChange={(e) => setZoom(Number(e.target.value))}
                      className="w-full accent-blue-500"
                    />
                  </div>

                  {/* Crop and Reset Buttons */}
                  <div className="mt-4 flex justify-between gap-2">
                    <button
                      onClick={handleCrop}
                      className="bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 text-white py-2 px-6 rounded-lg shadow-lg transition-transform transform hover:scale-105"
                    >
                      ✂️ Crop
                    </button>
                    <button
                      onClick={() => {
                        setImagePreview(null);
                        setCrop({ x: 0, y: 0 });
                        setZoom(1);
                      }}
                      className="bg-gradient-to-r from-red-500 to-red-700 hover:from-red-600 hover:to-red-800 text-white py-2 px-6 rounded-lg shadow-lg transition-transform transform hover:scale-105"
                    >
                      🔄 Reset
                    </button>
                  </div>
                  <div className="min-h-[44px] relative">
                    {/* {alertMessage && (
      <div className="absolute text-red-600 text-sm font-medium">
        {alertMessage}
      </div>
    )} */}
                  </div>
                </div>
              )}
            </div>

            {/* <button
              onClick={openModal}
              className="bg-gray-200 text-black rounded-full w-full h-10 mt-4 hover:bg-gray-300 transition duration-300"
            >
              Crop
            </button> */}

            <ToastContainer />
            <button
  onClick={handleDownloadClick}
  disabled={isProcessing || isUploaded}
  className={`w-full h-10 mt-4 rounded-full ${
    isProcessing || isUploaded
      ? "bg-gray-400 cursor-not-allowed text-black"
      : "bg-green-500 text-white hover:bg-green-600 transition duration-300"
  }`}
>
  {isProcessing
    ? "Processing..."
    : isUploaded
    ? "Uploaded Successfully"
    : "Download"}
</button>

            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              contentLabel="Crop Modal"
            >
              <CropFun />
              {/* Close icon */}
              <div className="absolute top-[20px] right-0 bg-orange-500 p-[12px] drop-shadow-lg    h-10 w-10   rounded-full ">
                <button onClick={closeModal} className="">
                  {" "}
                  <FaTimes color={"#fff"} />
                </button>{" "}
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};
