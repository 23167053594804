import React, { useState } from "react";
import Image1 from "../components/assets/ChildrensDay_Thumbnail.jpg";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
// import Navbar from "../components/Navbar";
import Sidebar from "./Sidebar/Sidebar";
import { IoMdArrowRoundBack } from "react-icons/io";
import { NavLink } from "react-router-dom";

export const ChildrensDay = () => {
  const [inputText1, setInputText1] = useState("");
  const [inputText2, setInputText2] = useState("");
  const [inputText3, setInputText3] = useState("");
  const [intensity, setIntensity] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const charLimit = 19;
  const MrObjId = localStorage.getItem("mrID");

  const handleInputTextChange = (setter) => (e) => {
    if (e.target.value.length <= charLimit) {
      setter(e.target.value);
    }
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        if (video.duration <= 300) {
          setSelectedVideo(file);
        } else {
          alert("Video duration must be 5 minutes or less.");
        }
      };
      video.src = URL.createObjectURL(file);
    }
  };

  const handleDownloadClick = async () => {
    if (!inputText1 || !inputText2 || !inputText3) {
      toast.error("Please enter all required text fields.");
      return;
    }
    if (!selectedVideo) {
      toast.error("Please select a video file.");
      return;
    }
    toast.success("Processing your request...");
    setIsProcessing(true);
    try {
      const file = selectedVideo;
      const fileExtension = file.name.split(".").pop();
      const sanitizedFileName =
        `${inputText1}_${inputText2}_${inputText3}_${intensity}`.replace(
          /\s+/g,
          "_"
        );
      const fileName = `${sanitizedFileName}_${uuidv4()}.${fileExtension}`;
      const { data: presignedData } = await axios.post(
        "https://somprazquiz.digilateral.com/getPresignedUrlvd",
        { fileName, fileType: file.type }
      );
      const { uploadUrl, key } = presignedData;
      await axios.put(uploadUrl, file, {
        headers: { "Content-Type": file.type },
      });
      console.log("S3 Key:", key);
      const requestBody = {
        text1: inputText1,
        text2: inputText2,
        text3: inputText3,
        intensity,
        videoFile: key,
      };
      console.log("Request body:", requestBody);
      await axios.post(
        `https://dummy.digilateral.com/api/auth/create-card/children/${MrObjId}`,
        requestBody,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      toast.success("Video uploaded successfully!");
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="flex min-h-screen w-full">
      <Sidebar />
      <div className="flex flex-col flex-1">
        <div className="flex flex-row justify-between items-center relative top-[-8px] ">
          <NavLink className="p-5 flex relative left-[-36px] ">
            <div
              style={{ backgroundColor: "#F58420", color: "white" }}
              className="p-[12px] drop-shadow-lg    h-10 w-10   rounded-full relative  top-[-4px] left-[17px] sm:left-[20px]  "
            >
              <IoMdArrowRoundBack />
            </div>
          </NavLink>
          <div className="text-black text-[20px] font-bold relative left-[-120px] lg:left-[-320px] sm:left-[-270px] top-[-2px] mr-[18rem] mt-12 ">
            <p>Children's Day</p>
          </div>
        </div>
        <div className="flex justify-center items-center w-full mt-[-3rem] p-4 ml-20">
          <div className="w-full max-w-[700px] bg-white p-2 shadow-lg rounded-lg">
            <div className="w-full relative sm:max-w-[800px]">
              <div className="flex justify-center">
                <img
                  src={Image1}
                  alt="Image1"
                  className="w-full max-w-[50%] sm:max-w-[200px]"
                />
              </div>
              <div className="w-full mt-4 text-left">
                <label className="block text-gray-700 font-medium">Name*</label>
                <input
                  type="text"
                  className="w-full h-10 border-2 rounded-lg px-3 mt-1 focus:outline-none focus:ring-2 focus:ring-gray-300"
                  value={inputText1}
                  onChange={handleInputTextChange(setInputText1)}
                  placeholder="Enter Name"
                />
              </div>
              <div className="w-full mt-4 text-left">
                <label className="block text-gray-700 font-medium">
                  Specialty*
                </label>
                <input
                  type="text"
                  className="w-full h-10 border-2 rounded-lg px-3 mt-1 focus:outline-none focus:ring-2 focus:ring-gray-300"
                  value={inputText2}
                  onChange={handleInputTextChange(setInputText2)}
                  placeholder="Enter Specialty"
                />
              </div>
              <div className="w-full mt-4 text-left">
                <label className="block text-gray-700 font-medium">City*</label>
                <input
                  type="text"
                  className="w-full h-10 border-2 rounded-lg px-3 mt-1 focus:outline-none focus:ring-2 focus:ring-gray-300"
                  value={inputText3}
                  onChange={handleInputTextChange(setInputText3)}
                  placeholder="Enter City"
                />
              </div>
              <div className="w-full mt-4 text-left">
                <label className="block text-gray-700 font-medium">
                  Intensity*
                </label>
                <select
                  className="w-full h-10 border-2 rounded-lg px-3 mt-1 focus:outline-none focus:ring-2 focus:ring-gray-300"
                  value={intensity}
                  onChange={(e) => setIntensity(e.target.value)}
                >
                  <option value="">None</option>
                  <option value="soft">Soft</option>
                  <option value="hard">Hard</option>
                </select>
              </div>
              <div className="w-full mt-4 text-left">
                <label className="block text-gray-700 font-medium">
                  Upload Video*
                </label>
                <label className="bg-gray-300 text-black px-4 py-2 rounded cursor-pointer inline-block mt-2">
                  Choose File
                  <input
                    type="file"
                    accept="video/*"
                    onChange={handleVideoChange}
                    className="hidden"
                  />
                </label>
                {selectedVideo && (
                  <p className="mt-2 text-gray-600">
                    Selected: {selectedVideo.name}
                  </p>
                )}
              </div>
              <ToastContainer />
              <button
                onClick={handleDownloadClick}
                disabled={isProcessing}
                className={`w-full h-10 mt-4 rounded-full ${
                  isProcessing
                    ? "bg-gray-400 cursor-not-allowed text-black"
                    : "bg-green-500 text-white hover:bg-green-600 transition duration-300"
                }`}
              >
                {isProcessing ? "Processing..." : "Download"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
